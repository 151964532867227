import $ from 'jquery';
import 'what-input';

//import './slick/slick';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';



//$(document).foundation();


$(document).foundation().ready( function() {

  /* 1) Up-Button: Scroll up */
  $(function () {
    // scroll body to 0px on click
    $('#back-top').click(function () {
      $('body,html').animate({
        scrollTop: 0
      }, 800);
      return false;
    });
  });
});


/* 2) Hamburger-Menu: Change icon to cross*/
$('.navbar__menuButton').on('toggled.zf.responsiveToggle', function(e) {
            $('.menu-icon').toggleClass('cross');
});


/* 3) Main Navigation: Mark active menu item */
$('.mainNavigation__1thLevel .menuItem__1thLevel').click(function () {

    if (!$(this).hasClass("mainNavigation__item--megaMenuOpen")) {

        $('.mainNavigation__1thLevel .menuItem__1thLevel').removeClass("mainNavigation__item--megaMenuOpen");
        $(this).toggleClass("mainNavigation__item--megaMenuOpen");

        if ($(this).hasClass("is-submenu-parent")) {
            return false;
        }

    } else if ($(this).hasClass("mainNavigation__item--megaMenuOpen")) {
        $(this).removeClass("mainNavigation__item--megaMenuOpen");
    }
});


/* 4) Extensions: News - Filter für Listviews im Newsportal (Views: News-Items, Event-Items*/

$('.newsItemsList--toggle').click(function () {

    $('.newsItemsListView').show(400, "linear");
    $('.eventItemsListView').hide(400, "linear");


    $(this).addClass("active");
    $('.eventItemsList--toggle').removeClass("active");
    $('.eventItemsList--toggle').addClass("toggle");

    return false;
});

$('.eventItemsList--toggle').click(function () {

    $('.newsItemsListView').hide(400, "linear");
    $('.eventItemsListView').show(400, "linear");
    $(this).addClass("active");
    $('.newsItemsList--toggle').removeClass("active");
    $('.newsItemsList--toggle').addClass("toggle");

    return false;
});


/* 5) Extension: News*/
/* Description: Auslesen des Get-Parameters "data-view" der Buttons "Weitere Nachrichten"
*                bzw."Weitere Veranstaltungen" von der Startseite, um zu bestimmen, welche View
*                im Newsportal angezeigt werden soll (Events/News), wenn das Newsportal geladen wird.
*
* */
$(document).ready(function(event) {

    var newsViewType = getUrlParameter("data-view");
    if(newsViewType == "newsListView"){
        $('.newsItemsListView').show(400, "linear");
        $('.eventItemsListView').hide(400, "linear");
        $('.eventItemsList--toggle').removeClass("active");
        $('.newsItemsList--toggle').addClass("active");
        $('.eventItemsList--toggle').addClass("toggle");
    }
    else if(newsViewType == "eventListView"){

        $('.newsItemsListView').hide(400, "linear");
        $('.eventItemsListView').show(400, "linear");
        $('.newsItemsList--toggle').removeClass("active");
        $('.eventItemsList--toggle').addClass("active");
        $('.newsItemsList--toggle').addClass("toggle");
    }

});


/*(6)*/
/* Function: getUrLParameter
 *  Parameter: sParam
 *  Description: Auslesen der GET-Parameter
*/
var getUrlParameter = function getUrlParameter(sParam) {
     var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
 };





